@media screen and (max-width: 768px) {
  .HeaderSection {
    position: relative;

    overflow: hidden;

    box-sizing: content-box;
    height: 100vh;

    color: var(--text-white);
    background: var(--gradient-main);
  }

  .HeaderSection_welcomeSection {
    overflow: hidden;
  }

  .HeaderSection_overflow {
    position: absolute;
    top: 0;
    right: 0;
    bottom: initial;
    left: initial;

    height: 100vh;

    transform: scale(1);

    touch-action: none;
  }
  .HeaderSection_main {
    align-items: self-start;

    width: 100vw;
    margin-top: 0;
    padding-top: 60px;

    justify-self: center;
  }
  .HeaderSection_container {
    padding: 0 16px;
  }
  .HeaderSection_welcome {
    align-items: center;

    width: 100%;
  }
  .HeaderSection_title {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    line-height: 28px;

    width: 100%;

    text-align: center;
  }
  .HeaderSection_description {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;

    width: 100%;

    text-align: center;
  }

  .HeaderSection_buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    height: 120px;
    margin-top: 20px;
  }

  .HeaderSection_buttons a {
    width: 200px;
  }

  .HeaderSection_buttons a:last-child {
    margin-left: 0;
  }
}
