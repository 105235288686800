@media screen and (max-width: 768px) {
  .ContactUsSection {
    height: 763px;
    margin-top: 128px;
    padding: 20px 0;
  }

  .ContactUsSection_container {
    width: 100%;
    padding: 0 16px;

    place-self: initial;
  }

  .ContactUsSection_card {
    width: 100%;
    height: initial;
    padding: 48px 16px;
  }

  .ContactUsSection_peopleImgBlock {
    display: none;
  }

  .ContactUsSection_input {
    width: 100%;
  }

  .ContactUsSection_input__left {
    margin-right: 0;
  }

  .ContactUsSection_input__right {
    margin-left: 0;
  }

  .ContactUsSection_input__half,
  .ContactUsSection_input__3Quarters,
  .ContactUsSection_input__1Quarters {
    flex-basis: initial;
    flex-grow: initial;

    min-width: initial;
  }

  .ContactUsSection_formBlock {
    flex-basis: initial;
    flex-grow: initial;

    max-width: initial;
  }

  .ContactUsSection_form {
    max-width: inherit;
  }

  .ContactUsSection_title {
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
    line-height: 28px;

    margin-bottom: 28px;

    text-align: center;
  }

  .ContactUsSection_subtitle {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;

    text-align: center;
  }

  .ContactUsSection_textarea {
    width: 100%;
    height: 90px;
  }

  .ContactUsSection_formFooter {
    align-items: center;
    flex-direction: column;
  }

  .ContactUsSection_formButtonBlock {
    align-self: center;

    margin-top: 24px;
  }

  .ContactUsSection_submit {
    width: 180px;
    height: 56px;
  }

  .Button_text {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    line-height: 24px;
  }
}
