@media screen and (max-width: 768px) {
  .Footer {
    align-items: initial;
  }

  .Footer_nav {
    flex-direction: column;

    width: 100%;
    padding: 40px 16px 32px 16px;
  }

  .Footer_nav a {
    margin-right: 0;
  }

  .Footer_nav .Footer_logoLink {
    width: 100px;
    margin-right: 0;
    margin-bottom: 34px;
  }

  .Footer_nav .Footer_logoLink svg {
    width: 70px;
  }

  .Footer_linksMobile span {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;

    float: left;

    width: 50%;
    margin: 0 0 16px 0;

    cursor: pointer;
  }
  .Footer_links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    height: 120px;
  }
  .Footer_links a {
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;

    float: left;

    width: 50%;
    margin: 0 0 16px 0;
  }

  .Footer_privacy {
    font-size: 12px;
    font-weight: 400;
    font-style: normal;
    line-height: 18px;

    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;

    width: initial;
    margin: 32px 16px 0;

    color: var(--dark-50);
  }

  .Footer_copyright {
    margin-top: 24px;
  }

  .Footer_address {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    width: initial;
    margin: 24px 16px 24px;
  }

  .Footer_descriptionBlock {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    width: 100%;
    margin-top: 0;
    padding: 0 16px 40px 16px;
  }

  .Footer_checkbox {
    width: 60px;
  }
}
