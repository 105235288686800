@media screen and (max-width: 768px) {
  .ForEmployersSection {
    overflow: initial;
    justify-content: center;

    height: initial;
    min-height: 100vh;
    margin-top: 20px;
  }

  .ForEmployersSection_container {
    width: 100vw;
    padding: 0 16px;

    place-self: initial;
  }

  .ForEmployersSection_steps {
    width: 100%;
    height: 100%;
  }

  .ForEmployersSection_title {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;

    text-align: center;
    letter-spacing: 0.1em;
  }

  .ForEmployersSection_container h2 {
    font-size: 22px;
    font-weight: 400;
    font-style: normal;
    line-height: 26px;

    margin-top: 9px;
    margin-bottom: 27px;

    text-align: center;
  }
  .ForEmployersSection_container .__subtitle-1 {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;

    margin-bottom: 17px;

    text-align: center;
  }
  .ForEmployersSection_description {
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 20px;

    text-align: center;
  }
  .ForEmployersSection_chart {
    top: -62px;
    left: -4px;

    width: 142px;
  }
  .ForEmployersSection_step {
    position: relative;

    box-sizing: content-box;
    width: calc(100vw - 92px);
    height: initial;
    margin-bottom: 67px;
    padding: 49px 30px 45px 30px;
  }
  .ForEmployersSection_step:last-child {
    margin-bottom: 0;
  }
  .ForEmployersSection_step__first {
    bottom: initial;
    left: initial;

    margin-top: 47px;
  }
  .ForEmployersSection_step__second {
    bottom: initial;
    left: initial;
  }
  .ForEmployersSection_step__third {
    top: initial;
    right: initial;
  }
  .ForEmployersSection_steps {
    display: flex;
    flex-direction: column;
  }
  .ForEmployersSection_arrow__first {
    top: initial;
    bottom: -32px;
    left: 55%;

    transform: rotate(103deg);
  }

  .ForEmployersSection_arrow__second {
    top: initial;
    right: 164px;
    bottom: -32px;

    transform: scale(1, -1) rotate(285deg);
  }
}
