@import url("./components/header-menu/header-menu.mobile.css");
@import url("./components/header-section/header-section.mobile.css");
@import url("./components/benefit-section/benefits-section.mobile.css");
@import url("./components/for-employers-section/for-employers-section.mobile.css");
@import url("./components/etz-mobile-section/etz-mobile-section.mobile.css");
@import url("./components/supported-crypto-section/supported-crypto-section.mobile.css");
@import url("./components/faq-section/faq-section.mobile.css");
@import url("./components/contact-us-section/contact-us-section.mobile.css");
@import url("./components/footer/footer.mobile.css");
@import url("./components/notification/notification.mobile.css");
@import url("./components/change-password-placeholder/change-password-placeholder.mobile.css");
