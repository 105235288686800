@media screen and (max-width: 768px) {
  .HeaderMenu_burgerBlock {
    display: initial;
  }

  .HeaderMenu_logo svg {
    width: 94px;
  }

  .HeaderSection_menu {
    box-sizing: content-box;
    height: 32px;
    min-height: 32px;
    margin: 20px 16px;
  }

  .HeaderMenu_overflow {
    z-index: -1;
    height: 100vh;
    pointer-events: none;
    touch-action: none;
    position: absolute;
    right: 0;
  }

  .HeaderMenu_menu__mobile {
    z-index: var(--z-index-menu);
    height: 56px;
    color: var(--bright);
    -webkit-overflow-scrolling: touch;
    background-color: rgba(0, 0, 0, 0);
    flex-direction: column;
    justify-content: flex-start;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .HeaderMenu_menuContainer__open.HeaderMenu_menu__mobile {
    height: 100vh;
    background: var(--gradient-main);
  }

  .HeaderMenu_menu__mobileSticky.HeaderMenu_menu__mobile {
    background-color: var(--bright);
  }

  .HeaderMenu_menu {
    display: none !important;
  }

  .HeaderMenu_menuContainer {
    width: 100%;
    height: 32px;
    justify-content: space-between;
    padding: 28px 16px;
    display: flex;
  }

  .HeaderMenu_menuContainer__open {
    position: fixed;
  }

  .HeaderMenu_menuContainer__open .HeaderMenu_linksBlockMobile {
    display: flex;
  }

  .HeaderMenu_menuContainer__open .HeaderMenu_overflow {
    display: initial;
  }

  .HeaderMenu_links, .HeaderMenu_initial, .HeaderMenu_entryIconDark, .HeaderMenu_logoDark {
    display: none;
  }

  .HeaderMenu_burgerBlock {
    position: relative;
  }

  .HeaderMenu_burgerBlock, .HeaderMenu_burgerBlock input {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    border: none;
    outline: none;
  }

  .HeaderMenu_burgerBlock input {
    z-index: -999;
    opacity: 0;
    margin: 0;
  }

  .HeaderMenu_burger {
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 1px;
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .HeaderMenu_burger span {
    min-width: 100%;
    height: 2.5px;
    background-color: var(--bright);
    border-radius: 8px;
    transition: all .3s;
  }

  .HeaderMenu_burgerBlock > input:checked + .HeaderMenu_burger span:first-child {
    transform: rotate(-45deg)translate(-7px, 8px);
  }

  .HeaderMenu_burgerBlock > input:checked + .HeaderMenu_burger span:nth-child(2) {
    opacity: 0;
  }

  .HeaderMenu_burgerBlock > input:checked + .HeaderMenu_burger span:last-child {
    transform: rotate(45deg)translate(-5px, -7px);
  }

  .HeaderMenu_linksBlockMobile {
    width: 100vw;
    height: calc(100vh + (var(--vh-px, 0)  - 100vh)  - 42px);
    flex-direction: column;
    justify-content: space-between;
  }

  .HeaderMenu_linksMobile {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    margin: 0 16px;
    display: flex;
  }

  .HeaderMenu_linkMobile {
    width: fit-content;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }

  .HeaderMenu_linkMobile.HeaderMenu_linkMobile__active:after {
    content: "";
    width: 100%;
    height: 3px;
    background: #fff;
    border-radius: 8px;
    display: inline-block;
  }

  .HeaderMenu_linkMobile:last-child {
    margin-bottom: 0;
  }

  .HeaderMenu_buttonsMobile {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
    display: flex;
  }

  .HeaderMenu_buttonsMobile a {
    width: 280px;
    margin-bottom: 16px;
    display: grid;
  }

  .HeaderMenu_buttonsMobile a:last-child {
    margin-bottom: 0;
  }

  .HeaderMenu_menuContainer__open .HeaderMenu_linksBlockMobile {
    padding: 0 48px;
  }

  .HeaderMenu_menu__mobileSticky {
    position: fixed;
  }

  .HeaderMenu_menu__mobileSticky .HeaderMenu_menuContainer {
    box-shadow: 0 4px 20px rgba(0, 0, 0, .1);
  }

  .HeaderMenu_menu__mobileSticky .HeaderMenu_burger span {
    background-color: var(--primary);
  }

  .HeaderMenu_menu__mobileSticky .HeaderMenu_logoLight path {
    fill: var(--primary);
  }

  .HeaderMenu_headerGetFreeDemo {
    margin-right: 0;
  }

  .dropdown {
    width: fit-content;
    margin-bottom: 30px;
    padding-bottom: 20px;
    display: inline-block;
    position: relative;
  }

  .dropbtn {
    color: var(--bright);
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    padding: 0;
    font-family: Rubik, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
  }

  .dropdown-content {
    z-index: 1;
    min-width: 160px;
    background-color: #2479fe;
    display: none;
    position: absolute;
    box-shadow: 0 8px 16px rgba(0, 0, 0, .1);
  }

  .dropdown-content a {
    color: #fff;
    padding: 12px 16px;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    color: var(--primary);
    background-color: #fff;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .HeaderMenu_menu__sticky .dropbtn {
    color: var(--text-black);
  }
}

@media screen and (max-width: 768px) {
  .HeaderSection {
    box-sizing: content-box;
    height: 100vh;
    color: var(--text-white);
    background: var(--gradient-main);
    position: relative;
    overflow: hidden;
  }

  .HeaderSection_welcomeSection {
    overflow: hidden;
  }

  .HeaderSection_overflow {
    top: 0;
    right: 0;
    bottom: initial;
    left: initial;
    height: 100vh;
    touch-action: none;
    position: absolute;
    transform: scale(1);
  }

  .HeaderSection_main {
    width: 100vw;
    justify-self: center;
    align-items: self-start;
    margin-top: 0;
    padding-top: 60px;
  }

  .HeaderSection_container {
    padding: 0 16px;
  }

  .HeaderSection_welcome {
    width: 100%;
    align-items: center;
  }

  .HeaderSection_title {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .HeaderSection_description {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .HeaderSection_buttons {
    height: 120px;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
    display: flex;
  }

  .HeaderSection_buttons a {
    width: 200px;
  }

  .HeaderSection_buttons a:last-child {
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .BenefitsSection {
    box-sizing: border-box;
    height: 100%;
    margin-top: 96px;
    padding: 0 16px;
    position: relative;
    top: 0;
    bottom: 0;
  }

  .BenefitsSection_container {
    width: calc(100vw - 32px);
    height: 100vh;
    justify-content: flex-end;
    place-self: initial;
    flex-direction: column;
    display: flex;
  }

  .BenefitsSection_title {
    text-align: center;
    letter-spacing: .1em;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .BenefitsSection_container h2 {
    text-align: center;
    margin-top: 8px;
    margin-bottom: 32px;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .BenefitsSection_slideContainer {
    min-width: calc(100vw - 62px);
  }

  .BenefitsSection_viewSlider {
    width: calc(100vw - 32px);
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0;
    margin-left: 0;
    padding: 0 15px;
    display: flex;
  }

  .BenefitsSection_shadow {
    box-shadow: 0 0 10px 25px var(--bright);
  }

  .BenefitsSection_slide {
    --position: 100vw;
    width: calc(100vw - 62px);
    min-width: initial;
    -webkit-transform: translateX(var(--position) );
    -moz-transform: translateX(var(--position) );
    -ms-transform: translateX(var(--position) );
    -o-transform: translateX(var(--position) );
    transform: translateX(var(--position) );
    align-items: center;
    padding: 0;
  }

  .BenefitsSection_slide__shown {
    --position: 0;
  }

  .BenefitsSection_slideImg {
    display: none;
  }

  .BenefitsSection_dots {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 81px;
    display: flex;
  }

  .BenefitsSection_dot {
    margin: 0 8px;
  }

  .BenefitsSection_slide span {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .ForEmployersSection {
    overflow: initial;
    height: initial;
    min-height: 100vh;
    justify-content: center;
    margin-top: 20px;
  }

  .ForEmployersSection_container {
    width: 100vw;
    place-self: initial;
    padding: 0 16px;
  }

  .ForEmployersSection_steps {
    width: 100%;
    height: 100%;
  }

  .ForEmployersSection_title {
    text-align: center;
    letter-spacing: .1em;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .ForEmployersSection_container h2 {
    text-align: center;
    margin-top: 9px;
    margin-bottom: 27px;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .ForEmployersSection_container .__subtitle-1 {
    text-align: center;
    margin-bottom: 17px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .ForEmployersSection_description {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .ForEmployersSection_chart {
    width: 142px;
    top: -62px;
    left: -4px;
  }

  .ForEmployersSection_step {
    box-sizing: content-box;
    width: calc(100vw - 92px);
    height: initial;
    margin-bottom: 67px;
    padding: 49px 30px 45px;
    position: relative;
  }

  .ForEmployersSection_step:last-child {
    margin-bottom: 0;
  }

  .ForEmployersSection_step__first {
    bottom: initial;
    left: initial;
    margin-top: 47px;
  }

  .ForEmployersSection_step__second {
    bottom: initial;
    left: initial;
  }

  .ForEmployersSection_step__third {
    top: initial;
    right: initial;
  }

  .ForEmployersSection_steps {
    flex-direction: column;
    display: flex;
  }

  .ForEmployersSection_arrow__first {
    top: initial;
    bottom: -32px;
    left: 55%;
    transform: rotate(103deg);
  }

  .ForEmployersSection_arrow__second {
    top: initial;
    bottom: -32px;
    right: 164px;
    transform: scale(1, -1)rotate(285deg);
  }
}

@media screen and (max-width: 768px) {
  .EtzMobileSection {
    box-sizing: border-box;
    height: initial;
    min-height: 848px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 120px;
    padding: 0 16px;
  }

  .EtzMobileSection_block {
    align-items: initial;
    justify-content: initial;
    width: 100%;
    height: initial;
    place-self: initial;
    flex-direction: column;
    padding-top: 0;
  }

  .EtzMobileSection_description {
    z-index: 50;
    min-width: initial;
  }

  .EtzMobileSection_title {
    text-align: center;
    letter-spacing: .1em;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .EtzMobileSection_subTitle {
    width: 100%;
    text-align: center;
    margin: 8px 0 32px;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .EtzMobileSection_textContainer {
    text-align: center;
    padding: 0;
  }

  .EtzMobileSection_text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .EtzMobileSection_mobiles {
    height: 508px;
    pointer-events: none;
    transform: translateX(25%);
  }

  .EtzMobileSection_img {
    height: 508px;
  }

  .EtzMobileSection_eth {
    right: 18px;
    bottom: calc(34px - var(--coin-position) );
  }

  .EtzMobileSection_btc {
    top: calc(156px + var(--coin-position) );
    left: 99px;
  }

  .EtzMobileSection_ada {
    top: calc(213px + var(--coin-position) );
    left: -175px;
  }

  .EtzMobileSection_avax {
    top: calc(104px + var(--coin-position) );
    left: 165px;
  }

  .EtzMobileSection_bnb {
    top: calc(-8px + var(--coin-position) );
    left: -171px;
  }

  .EtzMobileSection_btc {
    top: calc(21px + var(--coin-position) );
    left: 24px;
  }

  .EtzMobileSection_ftm {
    top: calc(-52px + var(--coin-position) );
    left: -177px;
  }

  .EtzMobileSection_sol {
    top: calc(-14px + var(--coin-position) );
    right: 43px;
  }

  .EtzMobileSection_usdc {
    bottom: calc(-64px - var(--coin-position) );
    left: 12px;
  }

  .EtzMobileSection_pattern {
    top: 0;
    right: 0;
    left: initial;
    transform: initial;
  }
}

@media screen and (max-width: 768px) {
  .SupportedCryptoSection {
    min-height: 1000px;
    justify-content: flex-start;
    margin-top: 40px;
    padding: 0;
  }

  .SupportedCryptoSection_title {
    letter-spacing: .1em;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .SupportedCryptoSection_subtitle {
    margin-bottom: 32px;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .SupportedCryptoSection_container {
    width: calc(100vw - 32px);
    align-items: center;
    place-self: initial;
    flex-direction: column;
    display: flex;
  }

  .SupportedCryptoSection_table {
    width: calc(100vw - 32px);
    height: initial;
    background: var(--primary-05);
    border-radius: 20px;
    flex-flow: column wrap;
    margin-top: 0;
    padding: 23px 8px;
    display: flex;
  }

  .SupportedCryptoSection_item {
    margin: 8px 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .SupportedCryptoSection_numberCoin {
    min-width: 16px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .SupportedCryptoSection_container {
    padding: 48px;
  }

  .SupportedCryptoSection_table {
    width: 100%;
    flex-flow: wrap;
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .FAQSection {
    min-height: 100%;
    margin-top: var(--section-margin-top);
    padding: 0;
  }

  .FAQSection_accordionBlock {
    width: 100%;
    padding: 0 16px;
  }

  .FAQSection_title {
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }

  .FAQSection_subtitle {
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }

  .FAQSection_container {
    width: 100vw;
    place-self: initial;
  }

  .FAQSection_accordion {
    margin-bottom: 16px;
  }

  .FAQSection_accordionSummary {
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .FAQSection_accordionDetailsDescription {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .FAQSection_accordionColumns {
    -webkit-column-count: 1;
    -moz-column-count: 2;
    column-count: 1;
    -webkit-column-gap: 64px;
    -moz-column-gap: 64px;
    column-gap: 64px;
  }

  .FAQSection_accordionDetailsItem__50 {
    flex-basis: initial;
    flex-grow: initial;
    min-width: initial;
    max-width: initial;
    margin-right: initial;
  }

  .FAQSection_accordionDetailsTitle {
    align-self: stretch;
    margin-bottom: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .FAQSection_accordionDetailsDescription {
    flex: none;
    order: 1;
    align-self: stretch;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .FAQSection_accordionSummary {
    padding: 21px 16px;
  }

  .FAQSection_accordionDetails {
    padding: 0 16px;
  }
}

@media screen and (max-width: 768px) {
  .ContactUsSection {
    height: 763px;
    margin-top: 128px;
    padding: 20px 0;
  }

  .ContactUsSection_container {
    width: 100%;
    place-self: initial;
    padding: 0 16px;
  }

  .ContactUsSection_card {
    width: 100%;
    height: initial;
    padding: 48px 16px;
  }

  .ContactUsSection_peopleImgBlock {
    display: none;
  }

  .ContactUsSection_input {
    width: 100%;
  }

  .ContactUsSection_input__left {
    margin-right: 0;
  }

  .ContactUsSection_input__right {
    margin-left: 0;
  }

  .ContactUsSection_input__half, .ContactUsSection_input__3Quarters, .ContactUsSection_input__1Quarters {
    flex-basis: initial;
    flex-grow: initial;
    min-width: initial;
  }

  .ContactUsSection_formBlock {
    flex-basis: initial;
    flex-grow: initial;
    max-width: initial;
  }

  .ContactUsSection_form {
    max-width: inherit;
  }

  .ContactUsSection_title {
    text-align: center;
    margin-bottom: 28px;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }

  .ContactUsSection_subtitle {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .ContactUsSection_textarea {
    width: 100%;
    height: 90px;
  }

  .ContactUsSection_formFooter {
    flex-direction: column;
    align-items: center;
  }

  .ContactUsSection_formButtonBlock {
    align-self: center;
    margin-top: 24px;
  }

  .ContactUsSection_submit {
    width: 180px;
    height: 56px;
  }

  .Button_text {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

@media screen and (max-width: 768px) {
  .Footer {
    align-items: initial;
  }

  .Footer_nav {
    width: 100%;
    flex-direction: column;
    padding: 40px 16px 32px;
  }

  .Footer_nav a {
    margin-right: 0;
  }

  .Footer_nav .Footer_logoLink {
    width: 100px;
    margin-bottom: 34px;
    margin-right: 0;
  }

  .Footer_nav .Footer_logoLink svg {
    width: 70px;
  }

  .Footer_linksMobile span {
    float: left;
    width: 50%;
    cursor: pointer;
    margin: 0 0 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .Footer_links {
    height: 120px;
    flex-flow: column wrap;
    display: flex;
  }

  .Footer_links a {
    float: left;
    width: 50%;
    margin: 0 0 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .Footer_privacy {
    width: initial;
    color: var(--dark-50);
    flex-direction: column-reverse;
    justify-content: flex-start;
    margin: 32px 16px 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .Footer_copyright {
    margin-top: 24px;
  }

  .Footer_address {
    width: initial;
    margin: 24px 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .Footer_descriptionBlock {
    width: 100%;
    flex-flow: column wrap;
    margin-top: 0;
    padding: 0 16px 40px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: flex;
  }

  .Footer_checkbox {
    width: 60px;
  }
}

@media screen and (max-width: 768px), (max-width: 1200px) {
  .Notification {
    max-width: 100vw;
    padding: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .Notification_messageContainer {
    max-width: calc(100vw - 32px);
    padding: 14px 14px 14px 24px;
  }

  .Notification_exit {
    width: initial;
    min-width: 14px;
    height: initial;
    min-height: 14px;
    margin: 0;
    padding: 5px 5px 10px 15px;
  }
}





/*# sourceMappingURL=index.2b0a7e62.css.map */
