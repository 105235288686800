@media screen and (max-width: 768px) {
  .EtzMobileSection {
    align-items: flex-start;
    /* overflow: initial; */
    justify-content: flex-start;

    box-sizing: border-box;
    height: initial;
    min-height: 848px;
    margin-top: 120px;
    padding: 0 16px;
  }

  .EtzMobileSection_block {
    align-items: initial;
    flex-direction: column;
    justify-content: initial;

    width: 100%;
    height: initial;
    padding-top: 0;

    place-self: initial;
  }

  .EtzMobileSection_description {
    z-index: 50;

    min-width: initial;
  }

  .EtzMobileSection_title {
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 18px;

    text-align: center;
    letter-spacing: 0.1em;
  }

  .EtzMobileSection_subTitle {
    font-size: 22px;
    font-weight: 400;
    font-style: normal;
    line-height: 26px;

    width: 100%;
    margin: 8px 0 32px 0;

    text-align: center;
  }

  .EtzMobileSection_textContainer {
    padding: 0;
    text-align: center;
  }

  .EtzMobileSection_text {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 22px;
  }

  .EtzMobileSection_mobiles {
    /* top: -54px;
    left: 50%;
    height: 426px; */
    height: 508px;

    transform: translateX(25%);
    pointer-events: none;
  }

  .EtzMobileSection_img {
    /* min-height: 100%; */
    height: 508px;
  }

  .EtzMobileSection_eth {
    right: 18px;
    bottom: calc(34px - var(--coin-position));
  }

  .EtzMobileSection_btc {
    top: calc(156px + var(--coin-position));
    left: 99px;
  }

  .EtzMobileSection_ada {
    top: calc(213px + var(--coin-position));
    left: -175px;
  }

  .EtzMobileSection_avax {
    top: calc(104px + var(--coin-position));
    left: 165px;
  }

  .EtzMobileSection_bnb {
    top: calc(-8px + var(--coin-position));
    left: -171px;
  }

  .EtzMobileSection_btc {
    top: calc(21px + var(--coin-position));
    left: 24px;
  }

  .EtzMobileSection_ftm {
    top: calc(-52px + var(--coin-position));
    left: -177px;
  }

  .EtzMobileSection_sol {
    top: calc(-14px + var(--coin-position));
    right: 43px;
  }

  .EtzMobileSection_usdc {
    bottom: calc(-64px - var(--coin-position));
    left: 12px;
  }

  .EtzMobileSection_pattern {
    top: 0;
    right: 0;
    left: initial;

    transform: initial;
  }
}
