@media screen and (max-width: 768px) {
  .HeaderMenu_burgerBlock {
    display: initial;
  }

  .HeaderMenu_logo svg {
    width: 94px;
  }

  .HeaderSection_menu {
    box-sizing: content-box;
    height: 32px;
    min-height: 32px;
    margin: 20px 16px;
  }

  .HeaderMenu_overflow {
    position: absolute;
    z-index: -1;
    right: 0;
    /* width: calc(100vh * 16 / 9); */
    height: 100vh;
    pointer-events: none;
    touch-action: none;
  }

  .HeaderMenu_menu__mobile {
    position: absolute;
    z-index: var(--z-index-menu);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 56px;
    color: var(--bright);
    background-color: transparent;
    inset: 0;
    -webkit-overflow-scrolling: touch;
  }

  .HeaderMenu_menuContainer__open.HeaderMenu_menu__mobile {
    height: 100vh;
    background: var(--gradient-main);
  }

  .HeaderMenu_menu__mobileSticky.HeaderMenu_menu__mobile {
    background-color: var(--bright);
  }

  .HeaderMenu_menu {
    display: none !important;
  }

  .HeaderMenu_menuContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 32px;
    padding: 28px 16px;
  }

  .HeaderMenu_menuContainer__open {
    position: fixed;
  }

  .HeaderMenu_menuContainer__open .HeaderMenu_linksBlockMobile {
    display: flex;
  }

  .HeaderMenu_menuContainer__open .HeaderMenu_overflow {
    display: initial;
  }

  .HeaderMenu_links {
    display: none;
  }

  .HeaderMenu_initial {
    display: none;
  }

  .HeaderMenu_entryIconDark {
    display: none;
  }

  .HeaderMenu_logoDark {
    display: none;
  }

  .HeaderMenu_burgerBlock {
    position: relative;
  }

  .HeaderMenu_burgerBlock,
  .HeaderMenu_burgerBlock input {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    border: none;
    outline: none;
  }

  .HeaderMenu_burgerBlock input {
    z-index: -999;
    margin: 0;
    opacity: 0;
  }

  .HeaderMenu_burger {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px 1px;
    cursor: pointer;
  }

  .HeaderMenu_burger span {
    min-width: 100%;
    height: 2.5px;
    transition: all 300ms ease;
    border-radius: 8px;
    background-color: var(--bright);
  }

  .HeaderMenu_burgerBlock
    > input:checked
    + .HeaderMenu_burger
    span:first-child {
    transform: rotate(-45deg) translate(-7px, 8px);
  }

  .HeaderMenu_burgerBlock
    > input:checked
    + .HeaderMenu_burger
    span:nth-child(2) {
    opacity: 0;
  }

  .HeaderMenu_burgerBlock > input:checked + .HeaderMenu_burger span:last-child {
    transform: rotate(45deg) translate(-5px, -7px);
  }

  .HeaderMenu_linksBlockMobile {
    flex-direction: column;
    justify-content: space-between;
    width: 100vw;
    height: calc(100vh + (var(--vh-px, 0) - 100vh) - 42px);
  }

  .HeaderMenu_linksMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: 0 16px;
  }

  .HeaderMenu_linkMobile {
    padding-bottom: 20px;
    margin-bottom: 30px;
    width: fit-content;
  }

  .HeaderMenu_linkMobile.HeaderMenu_linkMobile__active::after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 3px;
    background: #ffffff;
    border-radius: 8px;
  }

  .HeaderMenu_linkMobile:last-child {
    margin-bottom: 0;
  }

  .HeaderMenu_buttonsMobile {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 40px 0;
  }

  .HeaderMenu_buttonsMobile a {
    width: 280px;
    display: grid;
    margin-bottom: 16px;
  }

  .HeaderMenu_buttonsMobile a:last-child {
    margin-bottom: 0;
  }

  .HeaderMenu_menuContainer__open .HeaderMenu_linksBlockMobile {
    padding: 0 48px;
  }

  .HeaderMenu_menu__mobileSticky {
    position: fixed;
  }

  .HeaderMenu_menu__mobileSticky .HeaderMenu_menuContainer {
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  }

  .HeaderMenu_menu__mobileSticky .HeaderMenu_burger span {
    background-color: var(--primary);
  }

  .HeaderMenu_menu__mobileSticky .HeaderMenu_logoLight path {
    fill: var(--primary);
  }

  .HeaderMenu_headerGetFreeDemo {
    margin-right: 0;
  }

  /* Dropdown Styles */
  .dropdown {
    position: relative;
    display: inline-block;
    width: fit-content;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }

  .dropbtn {
    background-color: transparent;
    color: var(--bright);
    font-family: "Rubik", sans-serif;
    font-weight: 300;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #2479fe;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1;
    min-width: 160px;
  }

  .dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 38px;
  }

  .dropdown-content a:hover {
    background-color: #ffffff;
    color: var(--primary);
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .HeaderMenu_menu__sticky .dropbtn {
    color: var(--text-black);
  }
}
